<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="12">
        <v-container fluid>
          <v-card
            outlined
            class="card-hover"
            v-if="['new', 'edit'].includes(action)"
          >
            <v-app-bar dense elevation="0" flat>
              <h3 class="font-weight-bold">{{ $t("badge.titles.badgeGallery") }}</h3>
              <v-spacer></v-spacer>
              <v-img
                class="mx-4"
                :src="require('@/assets/icons/cup.svg')"
                contain
                height="25px"
                max-height="25px"
                width="25px"
                max-width="25px"
              />
            </v-app-bar>
            <v-row
              class="d-flex justify-start ma-0 pa-0"
              v-if="['new', 'edit'].includes(action)"
            >
              <v-col cols="12" md="1">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-img
                      :aspect-ratio="1"
                      :lazy-src="require('@/assets/img/preview-img.png')"
                      :src="require('@/assets/img/preview-img.png')"
                    >
                      <v-fade-transition>
                        <v-overlay v-show="hover" absolute color="white">
                          <CropperPhotoDialog
                            :append-icon="false"
                            :hide-input="true"
                            :reference="`badge_empty`"
                            type="badge"
                            @update:badgesFiles="updateBadgeList($event)"
                            @rule:size="snackbar = $event"
                          />
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>
              </v-col>
              <v-col
                v-for="(badge, key) in clone.badgesFiles"
                :key="key"
                cols="12"
                md="1"
              >
                <v-hover v-if="!loading" :disabled="action === 'show'">
                  <template v-slot:default="{ hover }">
                    <v-img
                      :aspect-ratio="1"
                      :lazy-src="badge.url"
                      :src="badge.url"
                      contain
                      :class="{'grayscale': !clone.badges[key].active}"
                    >
                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="white">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                x-small
                                fab
                                color="white"
                                :loading="loading"
                                v-on="on"
                                class="mr-1"
                              >
                                <template v-slot:loader>
                                  <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="16"
                                    width="2"
                                  >
                                  </v-progress-circular>
                                </template>
                                <v-icon
                                  color="primary"
                                  :loading="loading"
                                  @click="optionsBadge(key)"
                                >mdi-cog</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 10px">
                              {{ $t("general.buttons.options") }}
                            </span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                x-small
                                fab
                                color="white"
                                :loading="loading"
                                v-on="on"
                              >
                                <template v-slot:loader>
                                  <v-progress-circular
                                    color="error"
                                    indeterminate
                                    size="16"
                                    width="2"
                                  >
                                  </v-progress-circular>
                                </template>
                                <v-icon
                                  color="error"
                                  :loading="loading"
                                  @click="deleteBadge(key)"
                                >mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 10px">
                              {{ $t("general.buttons.delete") }}
                            </span>
                          </v-tooltip>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-if="action === 'show' && groupedBadges && Object.keys(groupedBadges).length"
            outlined
            class="card-hover mb-2"
            v-for="(group, type) in groupedBadges"
            :key="type"
          >
            <v-app-bar dense elevation="0" flat>
              <h3 class="font-weight-bold">{{ group.classification ? translate(group.classification.languageKey) : '' }}</h3>
              <v-spacer></v-spacer>
              <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px"
                     width="25px" max-width="25px" />
            </v-app-bar>
            <v-row class="d-flex justify-start ma-0 pa-0">
              <v-col
                v-for="(badge, key) in group.badges"
                :key="key"
                cols="12"
                md="1"
              >
                <v-hover :disabled="action === 'edit' || action === 'new'">
                  <template v-slot:default="{ hover }">
                    <v-img
                      :aspect-ratio="1"
                      :lazy-src="badge.s3_url"
                      :src="badge.s3_url"
                      contain
                      :class="{'grayscale': !badge.active}"
                    >
                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="white">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                x-small
                                fab
                                color="white"
                                :loading="loading"
                                v-on="on"
                                class="mr-1"
                              >
                                <template v-slot:loader>
                                  <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="16"
                                    width="2"
                                  >
                                  </v-progress-circular>
                                </template>
                                <v-icon
                                  color="primary"
                                  :loading="loading"
                                  @click="optionsBadge(key, badge)"
                                >
                                  mdi-magnify
                                </v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 10px">
                              {{ $t("general.buttons.show") }}
                              <span style='font-size: 12px; color: #AAAAAA'>
                                {{ $t('menu.badge') }}
                              </span>
                            </span>
                          </v-tooltip>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" app bottom class="pb-12">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          {{ $t("general.buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      persistent
      v-if="editedItem"
      v-model="dialog"
      max-width="700px"
    >
      <v-card :light="true" color="white">
        <v-card-title>
          <v-row class="d-flex justify-space-between align-center ma-0 pa-0">
            <v-col cols="6">
              <span class="headline">{{ formTitle }}</span>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-switch
                class="ma-0 pa-0 mx-2"
                :label="$t('badge.fields.active')"
                v-model="editedItem.active"
                color="primary"
                hide-details
                :disabled="action === 'show'"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-row>
            <v-col cols="3" md="3" v-if="action === 'show'">
              <div class="card__items">
                <span
                  style="
                    display: flex;
                    border-radius: 0.2em;
                    background-color: rgb(250, 250, 250);
                    padding: 20px;
                  "
                >
                  <v-img
                    :aspect-ratio="1"
                    :lazy-src="editedItem.imageUrl"
                    :src="editedItem.imageUrl"
                  >
                  </v-img>
                </span>
              </div>
            </v-col>
            <v-col cols="9" v-if="action==='show'" class="text-center-container">
              <p class="text-justify scrollable-p">
                {{ !editedItem.description_text || editedItem.description_text === ""
                ? $t("badge.titles.notDescription") : editedItem.description_text }}
              </p>
            </v-col>
            <v-col cols="12" v-if="action !== 'show'">
              <v-form ref="form" v-model="valid">
                <div class="card__items">
                  <span
                    style="
                      display: flex;
                      border-radius: 0.2em;
                      background-color: rgb(250, 250, 250);
                      padding: 20px;
                    "
                  >
                    <v-select
                      v-model="editedItem.classificationId"
                      :items="badgeClassifications"
                      :label="$t('storage.fields.classificationType')"
                      item-value="id"
                      item-text="name"
                      item-color="primary"
                      class="required"
                      color="primary"
                      required
                      dense
                      outlined
                      clearable
                      :rules="requireRules"
                    />
                  </span>
                  <span
                    style="
                      display: flex;
                      border-radius: 0.2em;
                      background-color: rgb(250, 250, 250);
                      padding: 20px;
                    "
                  >
                    <v-textarea
                      v-model="editedItem.description_text"
                      :auto-grow="false"
                      :clearable="false"
                      :counter="3000"
                      :label="$t('badge.fields.description')"
                      :no-resize="true"
                      :row-height="40"
                      :rows="7"
                      :rules="lengthRules(3000).concat(limitEnterKeyPress)"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      dense
                      outlined
                    />
                  </span>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
            :color="action !== 'show' ? 'neuter' : 'primary'"
            :class="action !== 'show' ? '' : 'neuter'"
            :elevation="action !== 'show' ? '0' : ''"
            :text="action !== 'show'"
            small
            @click="close"
          >
            {{
              action === 'show' ? $t("general.buttons.close") : $t("general.buttons.cancel")
            }}
          </v-btn>
          <v-btn
            color="primary"
            class="t-bw-primary--text"
            elevation="0"
            small
            v-if="action !== 'show'"
            @click="saveBadge"
            :disabled="!valid"
          >
            {{
              $t("general.buttons.confirm")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import _ from "lodash";
  import i18n from "@/plugins/i18n";
  import CropperPhotoDialog from "@/components/common/CropperPhotoDialog.vue";
  import { mapGetters, mapActions } from "vuex";
  import LanguajeService from "@/services/LanguajeService";

  export default {
    name: "TabMediaLocation",
    components: { CropperPhotoDialog },
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data: () => ({
      ready: false,
      loading: false,
      isBadgeHover: false,
      clone: {},
      text: i18n.t("general.rules.size"),
      snackbar: false,
      editedItem: null,
      itemKey: 0,
      dialog: false,
      valid: false,
    }),

    computed: {
      ...mapGetters({
        badgeClassifications: "storage/GET_BADGE_CLASSIFICATIONS",
        lengthRules: "general/lengthRules",
        limitEnterKeyPress: "general/limitEnterKeyPress",
        requireRules: "general/requireRules",
      }),
      formTitle() {
        return (
          i18n.t("general.titles." + this.action) +
          " " +
          i18n.t("menu.badge")
        );
      },
      groupedBadges() {
        const grouped = _.groupBy(this.clone.badges, (badge) =>
          badge.classification ? badge.classification.type : 'unknown'
        );
        return _.mapValues(grouped, (badges, type) => {
          const classification = _.find(this.badgeClassifications, { type });
          return { classification, badges };
        });
      },
    },

    created() {
      this.clone = _.cloneDeep(this.item);
      this.$store.dispatch("storage/fetchBadgeClassifications");
    },

    methods: {
      ...mapActions({}),
      findBadgeClassifications(item) {
        const classification = _.find(this.badgeClassifications, {
          id: item.classificationId,
        });

        if (classification) {
          return this.translate(classification.languageKey);
        } else {
          return "-";
        }
      },
      async updateBadgeList(e) {
        let badgesFiles = [...this.clone.badgesFiles];
        let badges = [...this.clone.badges];

        let badge = {
          id: null,
          description: "",
          description_text: "",
          file_extension: "",
          active: true,
          file_associate_code: "",
          size: 0,
          classification: { id: null, type: "" },
          initialized: false,
        };

        badgesFiles.push(e);
        this.$set(this.clone, "badgesFiles", badgesFiles);
        this.$emit("update:badgesFiles", this.clone.badgesFiles);

        badges.push(badge);
        this.$set(this.clone, "badges", badges);
        this.$emit("update:badges", this.clone.badges);

        this.item.updateBadges = true;
        this.optionsBadge(this.clone.badges.length - 1);
      },
      deleteBadge(key) {
        this.loading = true;

        let badgesFiles = [...this.clone.badgesFiles];
        let badges = [...this.clone.badges];

        badgesFiles.splice(key, 1);
        badges.splice(key, 1);

        this.$set(this.clone, "badgesFiles", badgesFiles);
        this.$emit("update:badgesFiles", this.clone.badgesFiles);

        this.$set(this.clone, "badges", badges);
        this.$emit("update:badges", this.clone.badges);

        this.item.updateBadges = true;
        this.loading = false;
      },
      optionsBadge(key, badge = null) {
        if (badge) {
          this.editedItem = { ...badge, imageUrl: badge.s3_url };
        } else {
          let badges = [...this.clone.badges];
          let badgesFiles = [...this.clone.badgesFiles];
          this.editedItem = { ...badges[key], imageUrl: badgesFiles[key].url };
          this.itemKey = key;
        }
        this.dialog = true;
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key);
      },
      close() {
        if (!this.editedItem.initialized) {
          this.clone.badgesFiles.splice(this.clone.badgesFiles.length - 1, 1);
          this.$emit("update:badgesFiles", this.clone.badgesFiles);
          this.clone.badges.splice(this.clone.badges.length - 1, 1);
          this.$emit("update:badges", this.clone.badges);
        }

        this.editedItem = null;
        this.dialog = false;
      },
      saveBadge() {
        let badges = [...this.clone.badges];

        badges[this.itemKey] = { ...this.editedItem };

        this.$set(
          this.clone,
          "badges",
          _.map(badges, (b) => ({
            ...b,
            initialized: true,
          }))
        );
        this.$emit("update:badges", this.clone.badges);

        this.item.updateBadges = true;
        this.loading = false;
        this.dialog = false;
      },
      scapeUrl(url) {
        let partes = url.split("/");
        let basePath = partes.slice(0, 4).join("/");
        let resourcePath = partes.slice(4).join("/");
        resourcePath = encodeURIComponent(resourcePath);
        return `${basePath}/${resourcePath}`;
      },
      getFileExtension(url, item) {
        if (url.includes("s3-accelerate.amazonaws.com")) {
          return item.file_extension;
        } else {
          let lastSegment = url.split("/").pop();
          let matches = lastSegment.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
          if (matches && matches.length > 1) {
            return matches[1];
          } else {
            return "jpg";
          }
        }
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "");
      },
    },
  };
</script>

<style scoped lang="scss">
  .chip-image__input-label {
    transition: all 200ms;
    background: #03ac8c;
    color: white;
    padding: 4px;
    margin: 0 15px;
    position: relative;
    border-radius: 0 0 0.3em 0.3em;
  }

  .chip-logo__input-label {
    transition: all 200ms;
    background: #03ac8c;
    color: white;
    padding: 4px;
    margin: 0 15px;
    position: relative;
    border-radius: 0 0 0.3em 0.3em;
  }

  :deep(.images__is-hover) {
    display: none;
  }

  .column-image__hover i {
    transition: all 200ms;
    position: relative;
    top: 20px;
    opacity: 0;
  }

  .column-image__hover:hover {
    & i {
      top: 0;
      opacity: 100%;
    }
  }

  .column-logo__hover i {
    transition: all 200ms;
    position: relative;
    top: 20px;
    opacity: 0;
  }

  .column-logo__hover:hover {
    & i {
      top: 0;
      opacity: 100%;
    }
  }

  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  .grayscale {
    opacity: 0.5;
  }

  .text-center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .scrollable-p {
    max-height: 180px;
    overflow: auto;
    padding: 20px;
  }
</style>
